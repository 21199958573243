import React, { Component } from "react";

class CleaningEducation extends Component {
  state = {};
  render() {
    return (
      <div className="courseContainer">
        <div>
          <h1>
            <strong>
              IGU uddannelsesplan <span>(forslag)</span>{" "}
            </strong>
          </h1>
          <div
            className="
                  courseHeader"
          >
            <h2>
              Fag/rolle: <span>Rengøring</span>{" "}
            </h2>
            <h2>
              Se flere kurser på:{" "}
              <span>
                <a href="https://www.efteruddannelse.dk">tryk her</a>
              </span>{" "}
            </h2>
          </div>
          <hr />
          <div className="courseTable">
            <table className="courseTableContent">
              <tr>
                <td>Kursets navn og ID:</td>
                <td>Intensivt DK sprogkursus (99333)</td>
              </tr>
              <tr>
                <td>Dato for kurset:</td>
                <td>1.12.17</td>
              </tr>

              <tr>
                <td>Kursets adresse:</td>
                <td>Niels Brock København</td>
              </tr>

              <tr>
                <td>Kursets varighed:</td>
                <td>90,0 dage</td>
              </tr>
            </table>
          </div>
          <hr />
          <div className="courseTable">
            <table className="courseTableContent">
              <tr>
                <td>Kursets navn og ID:</td>
                <td>Kulturafstemt kommunikation i salg og service (47295)</td>
              </tr>
              <tr>
                <td>Dato for kurset:</td>
                <td>17.5.2018</td>
              </tr>

              <tr>
                <td>Kursets adresse:</td>
                <td>København Universitet – Kbh. city</td>
              </tr>

              <tr>
                <td>Kursets varighed:</td>
                <td>4,0 dage</td>
              </tr>
            </table>
          </div>
          <hr />
          <div className="courseTable">
            <table className="courseTableContent">
              <tr>
                <td>Kursets navn og ID:</td>
                <td>
                  Kvalitet og kundeservice i turist og rejsebranchen (46500)
                </td>
              </tr>
              <tr>
                <td>Dato for kurset:</td>
                <td>18.8.2018</td>
              </tr>

              <tr>
                <td>Kursets adresse:</td>
                <td>Hotel {"&"} restaurations skolen - Valby</td>
              </tr>

              <tr>
                <td>Kursets varighed:</td>
                <td>9,0 dage</td>
              </tr>
            </table>
          </div>
          <hr />

          <div className="courseTable">
            <table className="courseTableContent">
              <tr>
                <td>Kursets navn og ID:</td>
                <td>Hygiejne kursus – udvidet (22266)</td>
              </tr>
              <tr>
                <td>Dato for kurset:</td>
                <td>22.9.2018</td>
              </tr>

              <tr>
                <td>Kursets adresse:</td>
                <td>AMU Kursuscenter - Brøndby</td>
              </tr>

              <tr>
                <td>Kursets varighed:</td>
                <td>5,0 dage</td>
              </tr>
            </table>
          </div>
          <hr />
          <div className="courseTable">
            <table className="courseTableContent">
              <tr>
                <td>Kursets navn og ID:</td>
                <td>Produktkendskab (77711)</td>
              </tr>
              <tr>
                <td>Dato for kurset:</td>
                <td>1.11.2018</td>
              </tr>

              <tr>
                <td>Kursets adresse:</td>
                <td>Internt kursus</td>
              </tr>

              <tr>
                <td>Kursets varighed:</td>
                <td>18,0 dage</td>
              </tr>
            </table>
          </div>
          <hr />
          <div className="educationFooter">
            <p class="text-justify">
              <p>Til brugere af disse ovenstående informationer.</p>
              <p>
                <strong>*Print, kopier, bliv inspireret </strong> … du er mere
                end velkommen til at bruge ovenstående data som baggrund for
                jeres egen IGU uddannelsesplan. Vi er bare glade for at kunne
                støtte op om jeres initiativer. Ovenstående er en ekstra service
                startIGU har fremtryllet, så det for arbejdsgiver og for Borger
                er lettere at iværksætte en valid og relevant uddannelsesplan
                for IGU eleven. Denne plan ser vi som én af de meget vigtige
                elementer i at få succes og samskabelse omkring IGU forløbene.
              </p>{" "}
              <p>
                <strong>
                  ** Det er helt og aldeles Arbejdsgivers ansvar at denne IGU
                  Uddannelsesplan bliver ført ud i livet.
                </strong>
                Der skal gøres brug af de 16-20 uger, som lovgivningen
                foreskriver og ring evt. til lokale kommunes Jobcenter – spørg
                efter Integration, hvis du har spørgsmål til andre
                teknikaliteter ifm. IGU. FORSLAG: Skulle du som Arbejdsgiver
                ikke kunne udfylde op til minimum min. 16 ugers uddannelse, så
                tal en snak med din IGU elev, da denne efter aftale med dig,
                selv kan vælge nogle kurser/fag, som IGU eleven syntes virker
                interessante. Det kunne være at din IGU ansatte har et ønske om,
                at blive bedre til Micro Soft Office pakken, kunne tænke sig at
                få et Truck Certifikat eller har en naturlig interesse for den
                nye GDPR (person data) lovgivning og så kan de sidste uger der
                er til rådighed bruges på dette – til alles glæde! HUSK: det er
                stadigvæk Arbejdsgiver der skal registrere IGU Kurserne i IGU
                planen og ligeledes Arbejdsgiver der skal stå for tilmelding hos
                underviser. Alt hvad der har med IGU Uddannelsesplanen at gøre
                ansvarer Arbejdsgiver for{" "}
              </p>{" "}
              <p>
                <strong>
                  *** Det er også 100% Arbejdsgivers ansvar at du som IGU elev
                  bliver tilmeldt de relevante kurser
                </strong>
                I har valgt at IGU uddannelsesplanen skal bestå af. Skulle
                kurser – grundet for få deltagere eller sygdom – blive aflyst,
                så er det også Arbejdsgivers ansvar at finde et tilsvarende
                kursus og få IGU eleven tilmeldt på ny. Ved tilmelding af
                kurser, skal Arbejdsgiver huske at meddele underviser at der er
                tale om et IGU forløb og sende kopi af IGU aftale samt
                uddannelsesplan.
              </p>
              <div>
                <p style={{ display: "inline" }}>
                  Spørgsmål - kontakt os på:{"  "}
                  <a href="Kontakt@startIGU.nu"> Kontakt@startIGU.nu</a>
                </p>
                <p style={{ float: "right" }}>
                  Find os på:{"  "}
                  <a href="www.startIGU.nu">www.startIGU.nu</a>
                  {"  "}
                  (siten lanceres d. 14.3.2019)
                </p>
              </div>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default CleaningEducation;
