import React, { Component } from "react";

class About extends Component {
  state = {};

  render() {
    return (
      <div className="about-text-container">
        <div class="bigbox background">
          <div class="scrollarea">
            <h2 class="scrolltext"></h2>
          </div>
        </div>
        <div className="sec1">
          <h2> Hvad står IGU for?   test test</h2>
          <p class="text-justify">
            Ordet IGU er forkortelsen for{" "}
            <strong>Integrations Grund Uddannelsen.</strong> Kort beskrevet, så
            er IGU én 2-årig Jobrettet opkvalificering af Flygtninge og
            Familiesammenførte, så denne borgergruppe på sigt kommer til, at stå
            bedre rustet til at påtage sig arbejdsopgaver på det Danske
            Arbejdsmarked. IGU aftalen er forankret på arbejdspladsen og er en
            2-årig aftale imellem arbejdsgiver og arbejdstager. IGUén indeholder
            bl.a. optil 20 ugers Uddannelse af den ansatte, hvor Arbejdsgiver er
            med til at sikre at de uddannelsesretninger/Kurser den ansatte
            IGU-elev skal gennemføre matcher de arbejdsopgaver, der skal udføres
            i Virksomheden. Dette skal være med til at sikre ordinær ansættelse
            efter afslutning af den 2-årige IGU og bevare det varige
            tilhørsforhold til det danske arbejdsmarked.
          </p>
        </div>
        <div class="bigbox background">
          <div class="scrollarea">
            <h2 class="scrolltext"></h2>
          </div>
        </div>
        <div className="sec1">
          <h2> Hvad kan startIGU.nu?</h2>
          <p class="text-justify">
            startIGU.nu (Logo) er en hjemmeside, der fungerer som Jobbank for
            ledige borgere omfattet af Integrationsloven. Det vil være muligt
            for den ledige Borger ligesom det er muligt for jobcentrernes
            Jobkonsulenter /Social Rådgivere, at gå ind og søge på relevante
            stillinger. I jobbanken vil der være opslået både Private, Statslige
            og Kommunale IGU Jobopslag. startIGU.nu yder også konsulentbistand
            og tilbyder undervisning af Jobcentrets personale ift. IGU og dets
            mange muligheder, Så skulle I ville vide mere om IGU og om
            startIGU.nu ude i kommunerne og i de private virksomheder, så tag
            KONTAKT TIL OS HER! Allan Leerbæk har de sidste år undervist i bl.a.
            Netværk og Egedal modellen når vi taler Branchepakke konceptet (fra
            LG insight) og disse indsatser kan med god grund "flettes sammen" og
            løbende støtte op om hinanden! Sidst - men bestemt ikke mindst - så
            vil startIGU.nu være med til at sikre kvaliteten i det
            person-/profilmatch, der sker fra stilling er opslået/publiceret til
            den rette kandidat til IGUén er fundet.
          </p>
        </div>
        <div class="bigbox background">
          <div class="scrollarea">
            <h2 class="scrolltext"></h2>
          </div>
        </div>
        <div className="sec2">
          <h2> Mission/Vision/Mål:</h2>
          <p class="text-justify">
            startIGU.nu har én{" "}
            <strong>
              <span>Mission </span>{" "}
            </strong>{" "}
            om, at være med til at højne og på sigt sikre kvaliteten af de IGU
            rekrutteringer og efterfølgende IGU aftaler, der dagligt indgås
            imellem arbejdsgivere og arbejdstagere. Vores fokus vil bl.a. være
            på at sikre et stabilt og overlevelses muligt Lønniveau samt at
            sikre kvaliteten i Uddannelsesplanen. Disse 2 punkter ser vi som
            "altafgørende" for succes! <br /> <br />
            Hvis startIGU.nu sammen med det inkluderende arbejdsmarked kan løfte
            kvaliteten på Uddannelsesplanen samt de 2 ovenstående elementer, så
            er vi ikke i tvivl om at IGUén generelt vil få større opmærksomhed
            fra både Kommuner/Jobcentrer og fra det Private Erhvervsliv og her
            kommer vores{" "}
            <strong>
              <span>Vision</span>
            </strong>{" "}
            så ind; startIGU.nu vil være kommunernes og de private virksomheders
            fortrukne samarbejdspartner, når vi taler om opstart og publicering
            af de 2 årige IGU aftaler.
            <br /> <br />
            Vores{" "}
            <strong>
              <span>Mål</span>
            </strong>{" "}
            er skabe en "IGU platform", hvor Borgere og Jobkonsulenter sammen
            kan finde relevante IGU Jobs hos vores Samarbejdspartnere. Processen
            omkring dette vil blive et kontinuerligt fokuspunkt for os og skulle
            være med til at sikre en stabil tilførsel af nye IGU Jobopslag.
          </p>
        </div>
        <div class="bigbox background">
          <div class="scrollarea">
            <h2 class="scrolltext"></h2>
          </div>
        </div>
        <div className="sec1">
          <h2> Vi støtter …</h2>
          <p class="text-justify">
            Vi støtter målrettet organisationer og projekter, der har fokus på
            at få Flygtninge og Familiesammenførte Borgere ind på det danske
            arbejdsmarked. Dette for at støtte op om kulturforståelsen og
            samtidigt sikre integrationen!
          </p>
        </div>
        <div class="bigbox background">
          <div class="scrollarea">
            <h2 class="scrolltext"></h2>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
