import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
  render() {
    return (
      /* <div className="navbar fixed-bottom navbar-light bg-light">
        <div classNameName="footerMainPoints" onClick={this.togglePartner}>
          {" "}
          > vores IGU partnere{" "}
        </div>
        <div style={hiddenPatner} classNameName="partnerIGU">
          <a href="https://www.max.dk/" target="_blank">
            <img
              classNameName="footerLogos"
              src="images/Maxhamburger.jpg"
              alt="Maxhamburger"
            />
          </a>
        </div>{" "}
        <div style={hiddenPatner} classNameName="partnerIGU">
          <a href="https://lagkagehuset.dk/" target="_blank">
            <img
              classNameName="footerLogos"
              src="images/olesteen_masterlogo.jpg"
              alt="Ole_Steen"
            />
          </a>
        </div>{" "}
        <div style={hiddenPatner} classNameName="partnerIGU">
          <a href="https://n-drive.dk/" target="_blank">
            <img
              classNameName="footerLogos"
              src="images/green.png"
              alt="drive green"
            />
          </a>
        </div>{" "}
        <div style={hiddenPatner} classNameName="partnerIGU">
          <a href="http://www.dancleaning.dk/" target="_blank">
            <img
              classNameName="footerLogos"
              src="images/dancleaning.png"
              alt="dancleaning"
            />
          </a>
        </div>{" "}
    </div> */
      <footer className="flex-rw">
        <hr className="footer-seperator" />
        <ul className="footer-list-top">
          <li>
            <h4 className="footer-list-header">IGU</h4>
          </li>
          <li>
            <a
              href="/process"
              className="generic-anchor footer-list-anchor"
              itemProp="significantLink"
            >
              IGU Processen
            </a>
          </li>
          <li>
            <a
              href="/about"
              className="generic-anchor footer-list-anchor"
              itemProp="significantLink"
            >
              Om IGU
            </a>
          </li>
          <li>
            <a
              href="/contact"
              className="generic-anchor footer-list-anchor"
              itemProp="significantLink"
            >
              Kontakt os
            </a>
          </li>
        </ul>
        <ul className="footer-list-top">
          <li>
            <h4 className="footer-list-header">Samarbejde</h4>
          </li>

          <li>
            <a href="/partners" className="generic-anchor footer-list-anchor">
              Vi samarbejder med
            </a>
          </li>
          <li>
            <a
              href="/Home-Decor/cat/id/64"
              className="generic-anchor footer-list-anchor"
            >
              Bliv samarbejdspartner
            </a>
          </li>
        </ul>
        <ul className="footer-list-top">
          <li id="help">
            <h4 className="footer-list-header">Status</h4>
          </li>
          <li>
            <a
              href="/shop/about-contact"
              className="generic-anchor footer-list-anchor"
              itemProp="significantLink"
            >
              Vi Støtter
            </a>
          </li>
          <li>
            <a
              href="/faq.html"
              className="generic-anchor footer-list-anchor"
              itemProp="significantLink"
            >
              Referencer (coming soon)
            </a>
          </li>
        </ul>
        <section className="footer-social-section flex-rw">
          <span className="footer-social-overlap footer-social-connect">
            Find <p className="footer-social-small">Os</p> På
          </span>
          <span className="footer-social-overlap footer-social-icons-wrapper">
            <a
              href="https://www.pinterest.com/paviliongift/"
              className="generic-anchor"
              target="_blank"
              title="Pinterest"
              itemProp="significantLink"
            >
              <i className="fa fa-linkedin" />
            </a>
            <a
              href="https://www.facebook.com/paviliongift"
              className="generic-anchor"
              target="_blank"
              title="Facebook"
              itemProp="significantLink"
            >
              <i className="fa fa-facebook " />
            </a>

            <a
              href="https://www.youtube.com/channel/UCYgUODvd0qXbu_LkUWpTVEg"
              className="generic-anchor"
              target="_blank"
              title="Youtube"
              itemProp="significantLink"
            >
              <i className="fa fa-youtube" />
            </a>
          </span>
        </section>
        <section className="footer-bottom-section flex-rw">
          <div className="footer-bottom-wrapper">
            <i className="fa fa-copyright" role="copyright" /> 2019 IGU in{" "}
            <address className="footer-address" role="company address">
              Denmark
            </address>
            <em className="footer-bottom-rights"> - All Rights Reserved - </em>
          </div>
        </section>
      </footer>
    );
  }
}

export default Footer;
