import React, { Component } from "react";
import { Link } from "react-router-dom";
import KontorEducation from "./kontorEducation";

const courses = [
  {
    title: "Kontor"
  },
  {
    title: "Transport"
  },
  { title: "Håndværker" },
  {
    title: "Køkken"
  },
  {
    title: "Lager"
  },
  { title: "Produktion" },
  {
    title: "Rengøring"
  },
  { title: "Butik" }
];

class Education extends Component {
  state = {};
  render() {
    return (
      <div className="row ">
        {courses.map(course => {
          return (
            <div
              className="cardContainer col-md-5"
              style={{ margin: "0 auto" }}
            >
              {" "}
              <div className="education ">
                {" "}
                <Link to={`/Education/${course.title}`}>
                  <div class="card" style={{ width: "18rem" }}>
                    <img
                      style={{ height: "200px" }}
                      src={`images/${course.title}.png`}
                      class="card-img-top"
                      alt={course.title}
                    />
                    <div class="card-body">
                      <h5 class="card-title">{course.title}</h5>
                      <p>
                        Gå ind her og se eksempel på uddannelsesplan til{" "}
                        {course.title}{" "}
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Education;
