import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import Education from "./education";
import {
  NavDropdown,
  Navbar,
  Nav,
  NavItem,
  Form,
  FormControl,
  Button,
  Container
} from "react-bootstrap";

class NavBar extends Component {
  state = {};
  render() {
    return (
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="whight"
        variant="light"
        className="appNavBar"
      >
        <Navbar.Brand href="/" className="startIGULogo" />
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <NavLink className="nav-link" to="/">
              Hjem
            </NavLink>
            <NavLink className="nav-link" to="/jobs">
              Jobs
            </NavLink>
            <NavLink className="nav-link" to="/education">
              Uddannelse
            </NavLink>

            <NavLink className="nav-link" to="/process">
              IGU Processen
            </NavLink>
            <NavLink className="nav-link" to="/about">
              Om StartIGU
            </NavLink>
          </Nav>
          <Nav />
        </Navbar.Collapse>
      </Navbar>

      /*

      <nav className="header-color navbar navbar-expand-lg navbar-light">
        <NavLink className="navbar-brand" to="#">
          <div className="startIGULogo" />
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav">
            <li className="nav-item active">
              <NavLink className="nav-link" to="/">
                Hjem <span className="sr-only">(current)</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/jobs">
                Jobs
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/education">
                Uddannelse
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/contact">
                Kontakt os
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/Process">
                IGU Processen
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/about">
                Om StartIGU
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
      */
    );
  }
}

export default NavBar;
