import React, { Component } from "react";
import Pagination from "./pagination";

class Jobs extends Component {
  state = {
    isLoaded: false,
    items: [],
    page: 1,
    totalItems: []
  };

  componentDidMount() {
    fetch(`https://bankjobs.publicidadloca.com/api/job?page=${this.state.page}`)
      .then(res => res.json())
      .then(
        (result) => {


          console.log("result is :", result)
          this.setState({
            isLoaded: true,
            items: result.jobs,

          });


        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }
  renderJobs = () => {
    if (this.state.items && this.state.items.length > 0) {
      return this.state.items.map(job => (<tr style={{ cursor: "pointer" }} onClick={() => { this.props.history.push(`/jobs/${job._id}`) }} key={job._id}>



        <td style={{ width: '250px' }}>{job.uiJobId}</td>
        <td style={{ width: '550px' }}>{job.jobTitle}</td>
        <td style={{ width: '250px' }}>{job.cvDeadline}</td>

        <td style={{ width: '90px', cursor: "pointer" }}><button
          style={{
            width: "100%", backgroundColor: "white", color: '#d000d7',
            border: "solid 1px #d000d7"
          }}
          onClick={() => { this.props.history.push(`/jobs/${job._id}`) }}
          className="btn  btn-sm">ÅBEN</button></td>
      </tr>
      ))
    }
    return <tr ><td > <i class="fa fa-spinner"></i> </td></tr>
  }

  handlePageChange = (page) => {


    console.log(page, this.state.page)
    fetch(`https://bankjobs.publicidadloca.com/api/job?page=${page}`)
      .then(

        res => res.json())
      .then(
        (result) => {



          this.setState({
            isLoaded: true,
            items: result.jobs,

          });


        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )


  }
  render() {

    return <div>

      < table class="table table-hover" >
        <thead>
          <tr>
            <th scope="col">Jobnummer</th>
            <th scope="col">Jobtitel</th>
            <th scope="col">Deadline</th>

            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>

          {this.renderJobs()}
        </tbody>
      </table >





      {/*       
      <table className="table table-hover">
        <thead>
          <tr style={{ fontSize: '25px' }}>
            <th>Jobtitel</th>
            <th>Arbejdssted</th>
            <th>Deadline</th>
            <th>Startdato</th>
            <th ></th>
            <th></th>
          </tr>
        </thead>
        <tbody>

          {this.renderJobs()}
        </tbody>
      </table> */}
      < Pagination totalIetms={12} pageSize={10} onPageChange={this.handlePageChange} />
    </div >
  }
}

export default Jobs;
