import React, { Component } from "react";
import "./App.css";
import Main from "./components/main";
import NavBar from "./components/NavBar";
import Footer from "./components/footer";
import SideBar from "./components/sideBar";

class App extends Component {
  render() {
    return (
      <div className="App">
        <div className="AppContainer" style={{ width: "100%" }}>
          <NavBar style={{ width: "100%" }} />
          <SideBar />
          <Main />
          <Footer />
        </div>
      </div>
    );
  }
}

export default App;
