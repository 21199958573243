import React, { Component } from "react";

class WorkBuddy extends Component {
  state = {};
  render() {
    return (
      <div className="about-text-container">
      <div class="bigbox background">
          <div class="scrollarea">
            <h2 class="scrolltext"></h2>
          </div>
        </div>
        <div className="sec3">
          <h2> WORK BUDDY Certificeret virksomhed </h2>
          <p class="text-justify">
          
 
 Bliv startIGU WORK BUDDY certificeret virksomhed.
 Med det signalerer I, at I som virksomhed støtter op om Integrationen af Flygtninge-& Familiesammenførte i Danmark og at I har IGU ansættelsesforhold på jeres virksomhed.
 Vi har 2 ufravigelige krav til dig/jer som Virksomhed for at I bliver startIGU Certificeret.
 1.krav er at I opretter IGU forløb igennem startIGU, som vi så rekrutterer til.
 2.krav går på, at I skal sende min. 2 medarbejdere på WORK BUDDY kursus. 
          </p>
        </div>
        <div class="bigbox background">
          <div class="scrollarea">
            <h2 class="scrolltext"></h2>
          </div>
        </div>
        <div className="freeSec">

          <p class="text-justify">
          
 
          Når vi sammen har opfyldt de 2 krav og IGU eleven er startet i IGU hos jer, så vil du/I få udleveret: 
CERTIFICERET startIGU.nu VIRKSOMED - skilt i rustfrit stål.
          </p>
        </div>


<div class="bigbox background">
          <div class="scrollarea">
            <h2 class="scrolltext"></h2>
          </div>
        </div>
        
       
        <div className="sec3">

          <p class="text-justify">
          WORK BUDDY kursus er 4 timers undervisning, der giver nuanceret indblik i de mange forskellige kulturer vi møder, når vi arbejder med flygtninge. Undervisningen sætter ligeledes rammerne for, hvad det vil sige at være en god kollega og sætter rammerne for, hvad/hvor du som WORK BUDDY skal hjælpe. WORK BUDDY undervisningen vil guide dig til at støtte rigtigt op om en solid og professionel ON-BOARDING/opstart af din nye kollega, der kommer med an anden kulturel baggrund.


           
          </p>
          <p className="text-justify">
          Dette kursus afholder vi 9 gange pr./år med <strong>Intregrationsnet / Dansk Flygtningehjælp</strong> , der stiller lokaler og én erfaren Interkulturel Underviser til rådighed for disse sessioner. I startIGU er vi ikke i tvivl om, at denne ON-BOARDING fase er den vigtigste periode for et stabilt og langvarigt ansættelsesforhold og vi er derfor utroligt stolte over at kunne præsentere ét samarbejde med denne organisation - Integrationsnet (en del af Dansk Flygtningehjælp) 
          </p>
        </div>

        <div className="freeSec">
<div style={{textAlign:"center"}}><img src="/images/integrationsnet.png" alt="" style={{width:"600px"}}/></div>

</div>

      </div>
    );
  }
}

export default WorkBuddy;
