import React, { Component } from "react";
const partners = [
  {
    title: "MaxBurger",
    src: "/images/Maxhamburger.jpg",
    link: "https://www.max.dk"
  },
  {
    title: "OleSteen",
    src: "/images/olesteen_masterlogo.jpg",
    link: "https://lagkagehuset.dk"
  },
  {
    title: "green", src: "/images/greenEnergy.png",
    link: "https://n-drive.dk/?gclid=CjwKCAjwtajrBRBVEiwA8w2Q8Pazp6rU8be7fk7DitaFAZnf_USgjwfjY7ueQmd8Z6V1NAOVdHErFBoCwFAQAvD_BwE"
  },
  {
    title: "Dancleaning",
    src: "/images/dancleaning.png",
    link: "http://www.dancleaning.dk"
  },
  {
    title: "Frisk Snit",
    src: "/images/Frisk.png",
    link: "http://www.frisksnit.dk"
  },
  {
    title: "Humanizer",
    src: "/images/humanizer.png",
    link: "https://humanizer.dk"
  },
  {
    title: "Banin",
    src: "/images/Banin.jpg",
    link: "http://baninfoods.dk/"
  }
];
class Partners extends Component {
  render() {
    return (
      <div class="container">
        <div style={{ textAlign: "center" }} className="row">
          {partners.map(partner => {
            return (
              <div
                style={{ textAlign: "center", marginBottom: "60px" }}
                class="col-4"
              >
                <div class="card-container">
                  <div class="partnercard">
                    <div class="side">
                      <img src={partner.src} alt={partner.title} />
                    </div>
                    <div class="side back">
                      <a
                        style={{
                          textDecoration: "none",
                          color: "black",
                          fontSize: "30px",
                          fontFamily: "Georgia",
                          fontWeight: "bold"
                        }}
                        href={partner.link}
                      >
                        BESØG OS
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Partners;
