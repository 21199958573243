import React, { Component } from "react";

import { Link } from "react-router-dom";
import Slider from "./slider";

class LandingPage extends Component {
  state = {};
  render() {
    return (
      <div className="container landingContainer">
        <div
          className=""
          style={{ width: "100%", margin: "0px" }}
        >
          <div className="seeJobsAndVideosContainer">
            <div class="row justify-content-center">
              <Link to="/jobs">
                <div className="buttonjobs button-4">IGU Jobs</div>
              </Link>
            </div>
            <div
              class="row justify-content-center"
              style={{
                width: "100%",
                margin: "0 auto",
                padding: "0px",
                textAlign: "center"
              }}
            >
              <Link className="col col-6 col-sm buttonjobs button-4"
                to="/videos"
                style={{
                  textDecoration: "none",
                  color: "#d000d7"
                }}
              >
                <div >
                  {" "}

                  <div
                    className=""
                    style={{ fontSize: "30px", fontWeight: "900" }}
                  >
                    IGU film
                  </div>

                </div>
              </Link>
              <Link className="col col-6 col-sm buttonjobs button-4"
                to="/buddy"
                style={{
                  textDecoration: "none",
                  color: "#d000d7"
                }}
              >
                <div >

                  <div
                    className=""
                    style={{ fontSize: "30px", fontWeight: "900" }}
                  >
                    Work Buddy
                  </div>

                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="clear"></div>
      </div>

    );
  }
}

export default LandingPage;
