import React, { Component } from 'react';
import _ from 'lodash';
class Pagination extends Component {
    state = {}
    render() {
        const { totalIetms, pageSize, onPageChange } = this.props;
        const pageCount = Math.ceil(totalIetms / pageSize);

        const pages = _.range(1, pageCount + 1);
        return (
            <nav>
                <ul className="pagination justify-content-center" >
                    {pages.map(page => (<li style={{ margin: "50px 0 50px 0" }} key={page} class="page-item"><a style={{ color: "#d000d7" }} onClick={() => onPageChange(page)} class="page-link" href="#">{page}</a></li>)

                    )}



                </ul>
            </nav >
        );
    }
}

export default Pagination;