import React, { Component } from "react";

class Video extends Component {
  state = {};
  render() {
    return (
      <div className="container videos">
        <div className="row videoRow">
          <div className="col col-6 col-sm">
            <div className="embed-responsive embed-responsive-16by9 video">
              <iframe
                className="embed-responsive-item"
                src="https://www.youtube.com/embed/w5lajubYNaw"
                allowFullScreen
              />
            </div>
            <div className="languageContainer">
              <span className="language ">Arabisk</span>{" "}
              <img className="flagIcon" src="images/Arabic.png" />{" "}
            </div>
          </div>
          <div className="col col-6 col-sm ">
            <div className="embed-responsive embed-responsive-16by9 video">
              <iframe
                className="embed-responsive-item"
                src="https://www.youtube.com/embed/3i2mzaIrvnY"
                allowFullScreen
              />
            </div>
            <div className="languageContainer">
              <span className="language ">Dansk</span>{" "}
              <img className="flagIcon" src="images/danish.png" />{" "}
            </div>
          </div>
        </div>
        <div className="row videoRow">

          <div className="col col-6 col-sm ">
            <div className="embed-responsive embed-responsive-16by9 video">
              <iframe
                className="embed-responsive-item"
                src="https://www.youtube.com/embed/yCwEqBacfHU"
                allowFullScreen
              />
            </div>
            <div className="languageContainer">
              <span className="language ">Engelsk</span>{" "}
              <img className="flagIcon" src="images/england.jpg" />{" "}
            </div>
          </div>



          <div className="col col-6 col-sm ">
            <div className="embed-responsive embed-responsive-16by9 video">
              <iframe
                className="embed-responsive-item"
                src="https://www.youtube.com/embed/TRjSLVmN-j0"
                allowFullScreen
              />
            </div>
            <div className="languageContainer">
              <span className="language ">Farsi</span>{" "}
              <img className="flagIcon" src="images/iran.png" />{" "}
            </div>
          </div>
        </div>
        <div className="row videoRow">
          <div className="col col-6 col-sm ">
            <div className="embed-responsive embed-responsive-16by9 video">
              <iframe
                className="embed-responsive-item"
                src="https://www.youtube.com/embed/YfsvYxhZZ6E"
                allowFullScreen
              />
            </div>
            <div className="languageContainer">
              <span className="language ">Tigrinsk</span>{" "}
              <img className="flagIcon" src="images/Eritrea.jpg" />{" "}
            </div>
          </div>



          <div className="col col-6 col-sm ">
            <div className="embed-responsive embed-responsive-16by9 video">
              <iframe
                className="embed-responsive-item"
                src="https://www.youtube.com/embed/WRCklEmH3jk"
                allowFullScreen
              />
            </div>
            <div className="languageContainer">
              <span className="language ">Somali</span>{" "}
              <img className="flagIcon" src="images/somalia.png" />{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Video;
