import React, { Component } from 'react';

class Job extends Component {
    state = { isLoaded: false, item: [] }

    componentDidMount() {
        fetch(`https://bankjobs.publicidadloca.com/api/job/${this.props.match.params.id}`)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        item: result.job
                    });

                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }
    displayJob = () => {
        if (this.state.item) {
            return (
                <div style={{ padding: "30px" }} className="sec3">
                    <h1 style={{ textAlign: "center" }}>{this.state.item.jobTitle}</h1>
                    <hr className="jobhr" />
                    <div style={{ margin: "5px 0 20px 0" }}>
                        <p className="text-justify" style={{ display: "inline" }}> <strong>Start: {this.state.item.contractStart}</strong> </p>
                        <p className="text-justify" style={{ float: "right" }}> <strong> Slut: {this.state.item.contractEnd}</strong></p>
                    </div>


                    <p class="text-justify jobText">
                        <h2 style={{ display: "inline" }}>Arbejdsopgaver: </h2> {this.state.item.jobTasks}
                    </p>
                    <p className="text-justify jobText">
                        <h2 style={{ display: "inline" }}>Personlig profil:</h2> {this.state.item.personalProfile}
                    </p>
                    <p className="text-justify jobText">
                        <h2 style={{ display: "inline" }}>Sprog:</h2> {this.state.item.languageDemand}
                    </p>
                    <p className="text-justify jobText">
                        <h2 style={{ display: "inline" }}>Kørekort:</h2> {this.state.item.driverLicense}
                    </p>
                    <p className="text-justify jobText">
                        <h2 style={{ display: "inline" }} >WORK BUDDY:</h2> {this.state.item.workBody}
                    </p>
                    <p className="text-justify jobText">
                        <h2 style={{ display: "inline" }}>Arbejdstider:</h2> {this.state.item.workingHours}
                    </p>
                    <p className="text-justify jobText">
                        <h2 style={{ display: "inline" }}>løn:</h2> {this.state.item.salary}
                    </p>
                    <p className="text-justify jobText">
                        Er du interesseret? ..så klik på ansøg og send os dit CV senest <strong>({this.state.item.cvDeadline})</strong> , og husk at sætte IGU job nummer
                        <strong> ({this.state.item.uiJobId}) {" "}</strong>  i emnefelt.
                    </p>

                    <div style={{ textAlign: "center", marginTop: "40px" }}>
                        <a href="mailto: cv@startigu.nu"> <button
                            style={{
                                width: "20%", backgroundColor: "white", color: '#d000d7',
                                border: "solid 1px #d000d7", cursor: "pointer"
                            }}

                            className="btn  btn-sm">Ansøg</button></a>

                    </div>




                </div>
            );

        }
        else {
            return 'there are no job'
        }
    }

    render() {
        console.log(this.state.item)
        return this.displayJob();


    }
}

export default Job;