import React, { Component } from "react";

class Contact extends Component {
  state = {};
  render() {
    return (
      <div className="row" style={{ margin: "100px" }}>

        <div className="col col-md-6" style={{ textAlign: "center" }}>
          <div class="blog">
            <div class="title-box">
              <h3 style={{ color: "#d0bc57" }}>Allan leerbæk</h3>
              <hr />
              <div class="intro">Grundlægger Og Ansvarlig For Samarbejde</div>
            </div>
            <div class="info">
              <p>
                Allan er ansvarlig for samarbejdet med eksterne partnere - private
                som kommunale - og elsker at netværke. Han elsker at få en SMS når
                han er optaget og er elendig til at aflytte sin telefonsvarer, men
                tag endelig kontakt, han venter dit opkald.
          </p>
            </div>


            <div class="color-overlay" />

          </div>
          <div >
            <p style={{ textAlign: "center", marginTop: "25px" }}>
              <a
                href="tel:+4540418688"
                style={{ color: "#d000d7", margin: "10px" }}
              >
                <i class="fa fa-phone fa-2x" />{" "}
              </a>
              <a
                href="mailto:AL@startIGU.nu"
                style={{ color: "#d000d7", margin: "10px" }}
              >
                <i class="fa fa-at fa-2x" />{" "}
              </a>
            </p>
          </div>
        </div>
        <div className="col col-md-6">
          <div class="nicolineBlog">
            <div class="title-box">
              <h3 style={{ color: "#d0bc57" }}>Nicoline B.Sørensen</h3>
              <hr />
              <div class="intro">Ansvarlig For WORK BUDDY og IGU Processerne</div>
            </div>
            <div class="info">
              <p>
                Nicoline har helikopter perspektiv i sin tilgang og fungerer bedst, når der er fart over feltet. Fantastiske input og værdsat humor og latter. Uvurderlig kollega med et servicegen udover det sædvanlige.         </p>
            </div>

            <div class="color-overlay" />
          </div>
          <div >
            <p style={{ textAlign: "center", marginTop: "25px" }}>

              <a
                href="mailto:nbs@startigu.nu"
                style={{ color: "#d000d7", margin: "10px" }}
              >
                <i class="fa fa-at fa-2x" />{" "}
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
