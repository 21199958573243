import React, { Component } from "react";
import LandingPage from "./landingPage";
import Jobs from "./jobs";
import Education from "./education";

import About from "./about";
import NotFound from "./notFound";
import { Route, Switch, Redirect } from "react-router-dom";
import KontorEducation from "./kontorEducation";
import TransportEducation from "./transportEducation";
import WorkerEducation from "./workerEducation";
import KitchenEducation from "./kitchenEducation";
import WareHouseEducation from "./wareHouseEducation";
import ProductionEducation from "./productionEducation";
import CleaningEducation from "./cleaningEducation";
import ButikEducation from "./ButikEducation";
import Process from "./process";
import Video from "./video";
import OrderIGU from "./orderIGU";
import Contact from "./contact";
import Partners from "./partners";
import Job from "./job"
import WorkBuddy from './workbuddy';



class Main extends Component {
  state = {};
  render() {
    return (

      <div className="container">
        <Switch style={{ width: "100%" }} >
          <Route path="/jobs/:id" component={Job} />
          <Route path="/Education/kontor" component={KontorEducation} />
          <Route path="/Education/Transport" component={TransportEducation} />
          <Route path="/Education/Køkken" component={KitchenEducation} />
          <Route path="/Education/Rengøring" component={CleaningEducation} />
          <Route path="/Education/Butik" component={ButikEducation} />
          <Route path="/Education/Produktion" component={ProductionEducation} />
          <Route path="/Education/Lager" component={WareHouseEducation} />
          <Route path="/Education/Håndværker" component={WorkerEducation} />
          <Route path="/videos" component={Video} />
          <Route path="/buddy" component={WorkBuddy} />
          <Route path="/partners" component={Partners} />
          <Route exact path="/" component={LandingPage} />
          <Route path="/Jobs" component={Jobs} />
          <Route path="/contact" component={Contact} />
          <Route path="/process" component={Process} />
          <Route path="/Education" component={Education} />
          <Route path="/about" component={About} />
          <Route path="/orderIGU" component={OrderIGU} />

          <Route path="/NotFound" component={NotFound} />
          <Redirect from="/" exact to="/" />
          <Redirect to="/NotFound" />
        </Switch>
      </div>




    );
  }
}

export default Main;
