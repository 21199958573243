import React, { Component } from "react";
class OrderIGU extends Component {
  state = {};
  render() {
    return (
      <div className="about-text-container">
        <h2 style={{ textAlign: "center" }}> Bestil IGU </h2>


        <div className="orderIGU-text">
          <p class="text-justify">
            1. Der er 2 måder at gribe det an på, men som udgangspunkt tager vi
            2 individuelle samtaler med denne borger og får os tunet ind på
            dennes kvalifikationer, motivationspunkter og ønsker for fremtiden.
            Den ene af disse samtaler kan godt foregå telefonisk. Når vi har
            dannet os ét godt og relativt klart billede af denne borgers profil
            og vurderet mulighederne, så tager vi direkte kontakt til relevant
            arbejdsgiver i vores netværk og præsenterer profilen og mulighederne
            for arbejdsgiver. Dette er 1. fremgangsmåde...og måden vi i langt de
            fleste bestillingsopgaver atbejder ud fra.
          </p>
        </div>
        <div class="bigbox background">
          <div class="scrollarea">
            <h2 class="scrolltext"></h2>
          </div>
        </div>
        <div className="orderIGU-text">
          <p class="text-justify">
            2. fremgangsmåde fungerer således at I som kommune allerede har -
            med Borgeren - ét aftalt fagområde, som vi så i startIGU skal finde
            én IGU samarbejdspartner indenfor og indlede samtaler og
            forhandlinger med.
          </p>
        </div>
        <div class="bigbox background">
          <div class="scrollarea">
            <h2 class="scrolltext"></h2>
          </div>
        </div>
        <div className="orderIGU-text">
          <p class="text-justify">
            I både 1 og 2 fremgangsmåde aftales prisen case/case, når rammerne
            omkring IGU opgaven er skitseret. Fra indledende dialog til opstart
            af Virksomhedspraktik/IGU skal I regne med 2-4 ugers resonstid, som
            vi bruger fornuftigt på kontakttagen til relevante lokale
            virksomheder og første indledende møder med disse.
          </p>
        </div>
        <div class="bigbox background">
          <div class="scrollarea">
            <h2 class="scrolltext"></h2>
          </div>
        </div>
        <div className="orderIGU-text">
          <p style={{ textAlign: "center" }}>
            Opstarten vil oftest være én kort praktik af 1-3 ugers varighed
            inden opstart af IGU. Dette for alle parters skyld. Kontakt os for
            tilbud{" "}
          </p>
          <div className="">
            <p style={{ textAlign: "center" }}>
              <a
                href="tel:+4917640206387"
                style={{ color: "#d000d7", margin: "10px" }}
              >
                <i class="fa fa-phone fa-2x" />{" "}
              </a>
              <a
                href="mailto:AL@startIGU.nu"
                style={{ color: "#d000d7", margin: "10px" }}
              >
                <i class="fa fa-at fa-2x" />{" "}
              </a>
            </p>
          </div>

        </div>

      </div>
    );
  }
}

export default OrderIGU;
