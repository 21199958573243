import React, { Component } from "react";

class Process extends Component {
  state = {};
  render() {
    return (
      <div className="about-text-container">
        <div class="bigbox background">
          <div class="scrollarea">
            <h2 class="scrolltext"></h2>
          </div>
        </div>
        <div className="sec3">
          <h2> IGU processen hos os: </h2>
          <p class="text-justify">
            <ul>
              <li>
                Oprette ledige IGU stillinger - på startIGU.nu - Statslige,
                kommunale som Private stillinger
              </li>
              <li>
                Modtage og gennemgå indkomne CV og udpege de relevante profiler{" "}
              </li>
              <li>
                Præsentere relevante profiler til Virksomheden for godkendelse
                til samtale-runde hos Arbejdsgiver/Virksomheden
              </li>
              <li>Indkalde relevante profiler til samtale på Virksomheden</li>
              <li>
                Deltage ved IGU Jobsamtalerne og om nødvendigt medbringe Tolk{" "}
              </li>
              <li>
                Udfærdige en Uddannelsesplan for den valgte kandidat - i
                samarbejde med Arbejdsgiver/Virksomhed
              </li>
              <li>
                Udfærdiger et komplet data sheet med alle ønskede informationer
                omkring IGU forløbet, rammerne og andre aftalte detaljer. Dette
                leveres i 3 kopier til hhv. IGU eleven, Jobcentret og
                Arbejdsgiver.
              </li>
            </ul>
          </p>
        </div>
        <div class="bigbox background">
          <div class="scrollarea">
            <h2 class="scrolltext"></h2>
          </div>
        </div>
        <div className="sec3">
          <h2> IGU processen for arbejdsgiver: </h2>
          <p class="text-justify">
            <ul>
              <li>
                Du/I beskriver stillingens krav, rammer og vilkår - startIGU.nu
                publicerer Jobopslaget online og det sendes ud til alle
                regionale Jobcentre.
              </li>
              <li>
                2-4 relevante CV sendes til dig for godkendelse til interview,
                der afholdes indenfor 7 dage.
              </li>
              <li>
                30 min. Interview pr. godkendt CV, hvorefter du/I vælger rette
                kandidat.
              </li>
              <li>
                Opstart af 1-2 ugers Praktik og herefter opstart af 2-årig IGU
                aftale.
              </li>
            </ul>
          </p>
        </div>
        <div class="bigbox background">
          <div class="scrollarea">
            <h2 class="scrolltext"></h2>
          </div>
        </div>
        <div className="sec3">
          <h2> IGU proces for Myndighed/Sagsbehandler </h2>
          <p class="text-justify">
            <ul>
              <li>
                Du eller din Jobkonsulent finder det relevante IGU stillings
                opslag. Relevant ift. din erfaring og professionelle baggrund.
              </li>
              <li>
                Du/I vedhæfter dit CV via stillingsopslaget og den mailboks der
                afsender CV vil modtage kvittering. Herefter vil startIGU.nu
                gennemgå de CV der er modtaget og finde de rette kandidater til
                samtale på Virksomheden.
              </li>
              <li>
                Hvis du har den rette profil for stillingen vil du blive kaldt
                til Jobsamtale på Virksomheden indenfor 2 uger. Der vil være
                Tolk tilstede i de tilfælde det vurderes nødvendigt.
              </li>
              <li>
                Efter Jobsamtalen der varer ca. 30 min. vil det blive vurderet
                om du kan udføre de beskrevne arbejdsopgaver og om motivationen
                er tilstede hos dig.
              </li>
              <li>
                Hvis du bliver kandidaten Virksomheden vælger, så vil der
                hurtigt opstarte 2-4 ugers Praktik og herefter opstart af den
                2-årige IGU aftale.
              </li>
            </ul>
          </p>
        </div>
        <div class="bigbox background">
          <div class="scrollarea">
            <h2 class="scrolltext"></h2>
          </div>
        </div>
      </div>
    );
  }
}

export default Process;
