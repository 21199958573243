import React, { Component } from "react";
import { Link } from "react-router-dom";
class SideBar extends Component {
  state = {
    hidden: false
  };
  toggleElement = () => {
    this.setState({ hidden: !this.state.hidden });
  };

  render() {
    return (
      <div className="">
        <p className="">
          <button
            className="btn verticalButton"
            type="button"
            onClick={this.toggleElement}
            style={{
              backgroundColor: "#d000d7",
              color: "white",
              fontWeight: "bold",
              fontSize: "25px"
            }}
          >
            BESTIL IGU
          </button>
        </p>
        <div className="orderIGUText">
          {this.state.hidden ? (
            <div
              className="card card-body orderIGU"
              style={{ backgroundColor: "rgba(204, 204, 204, .7)", textAlign: 'center' }}
            >
              <p className="card-text" style={{ color: "#000" }}>
                Har du og din kommune én Borger som ønsker at komme i IGU
                forløb, så tag kontakt  <br /> til:
                <em style={{ fontSize: "20px", fontWeight: "bold" }}>
                  {" "}
                  Allan Leerbæk
                </em>{" "}
                <br />
                <div className="row justify-content-md-center">
                  <div className="col col-3">
                    <a href="tel:+4917640206387" style={{ color: "#d000d7", margin: "0 auto" }}>
                      <i class="fa fa-phone fa-2x" />{" "}
                    </a>
                  </div>
                  <div className="col col-3">
                    <a
                      href="mailto:AL@startIGU.nu"
                      style={{ color: "#d000d7" }}
                    >
                      <i class="fa fa-at fa-2x" />{" "}
                    </a>
                  </div>
                </div>
                <div
                  className="row justify-content-md-center"
                  style={{ marginTop: "20px" }}
                >
                  <div className="">
                    <Link to="orderIGU" className="orderIGU">
                      {" "}
                      Bestil IGU
                    </Link>
                  </div>
                </div>
              </p>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default SideBar;
